import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, TextField, Typography, Card, CardContent, Grid } from '@mui/material';
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import TagManager from "react-gtm-module";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const PaymentForm = ({ clientSecret, userEmail }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        // Send GTM data
        const dataLayer = {
            event: 'buttonClick',
            buttonName: 'Checkout'
        };
        TagManager.dataLayer({ dataLayer });

        event.preventDefault();

        if (!stripe || !elements) {
            console.log("Stripe.js hasn't loaded yet.");
            return;
        }

        const billingDetails = {
            name: event.target.name.value,
            email: userEmail,
            address: {
                city: event.target.city.value,
                line1: event.target.address.value,
                state: event.target.state.value,
                postal_code: event.target.zip.value,
            },
        };

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: billingDetails,
            },
        });

        if (result.error) {
            console.log(result.error.message);
            toast.error('Payment failed');
        } else {
            if(result.paymentIntent.status === "requires_capture"){

                // TODO Redirect to successful page
                navigate(`/refund`);
            }
        }
    };

    return (
        <Card sx={{ mx: 'auto', my: 5, p: 1, width: "100%" }}>
            <CardContent>
                <Typography variant="h4" component="div" gutterBottom sx={{ mb: 3 }}>
                    Payment Details
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Name" name="name" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth label="Address" name="address" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth label="City" name="city" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField required fullWidth label="State" name="state" />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField required fullWidth label="ZIP / Postal code" name="zip" />
                        </Grid>
                        <Grid item xs={12}>
                            <CardElement options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': { color: '#aab7c4' },
                                    },
                                    invalid: { color: '#9e2146' },
                                },
                            }} />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 4 }}
                        disabled={!stripe}
                    >
                        Pay
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

const CheckoutForm = ({ clientSecret, userEmail }) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm clientSecret={clientSecret} userEmail={userEmail}/>
        </Elements>
    );
};

export default CheckoutForm;
