import { Route, Routes } from 'react-router-dom';
import Register from './register';
import Login from './login';
import RefundNotification from './RefundNotification';
import { Toaster } from "react-hot-toast";
import NotFoundPage from "./404";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Checkout from "./checkout/Checkout";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function App() {

    return (
        <div>
            <Elements stripe={stripePromise}>
                <Toaster />
                <Routes>
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />
                    <Route path="refund" element={<RefundNotification />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="/" element={<Login />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Elements>
        </div>
    );
}
