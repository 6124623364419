import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Formik, Form, ErrorMessage, Field} from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import TagManager from "react-gtm-module";


function Copyright(props) {
    const { href, name, ...typographyProps } = props;
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...typographyProps}>
            {'Copyright © '}
            <Link color="inherit" href={href}>
                {name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



function SignIn() {

    // Send GTM data
    const dataLayer = {
        event: 'buttonClick',
        buttonName: 'Signin'
    };
    TagManager.dataLayer({ dataLayer });


    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().required('No password provided.'),
    });

    async function createPaymentIntent(name, email) {
        try {
            const response = await fetch(`https://ai.hivespark.io/api/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email
                })
            });
            if (!response.ok) {
                toast.error('Error: Unable to pay');
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data.clientSecret);
            return data.clientSecret;
        } catch (err) {
            console.error(err);
            toast.error("Error: Unable to make a payment by Stripe");
            throw err;
        }
    }




    const handleSubmit = (values, { setSubmitting }) => {
        fetch(`https://ai.hivespark.io/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: values.email,
                password: values.password,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    toast.error('Error: Unable to login, check your email address or password.')
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(async data => {
                console.log(data);

                // If user is paid, redirect to refund page
                if (data.userInfo.isStripePaid) {
                    navigate(`/refund`);
                } else {
                    // TODO: redirect to payment page
                    // Attempt to create a payment intent after successful registration
                    const clientSecret = await createPaymentIntent(values.lastName + " " + values.firstName, values.email);
                    toast.success("You will use Stripe to make payment");
                    console.log('clientSecret', clientSecret);
                    navigate('/checkout', {state: {clientSecret: clientSecret, userEmail: values.email}});
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Field as={TextField}
                                   margin="normal"
                                   required
                                   fullWidth
                                   id="email"
                                   label="Email Address"
                                   name="email"
                                   autoComplete="email"
                                   autoFocus
                            />
                            <ErrorMessage name="email" component="div" />
                            <Field as={TextField}
                                   margin="normal"
                                   required
                                   fullWidth
                                   name="password"
                                   label="Password"
                                   type="password"
                                   id="password"
                                   autoComplete="current-password"
                            />
                            <ErrorMessage name="password" component="div" />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={isSubmitting}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                {/*<Grid item xs>*/}
                                {/*    <Link href="#" variant="body2">*/}
                                {/*        Forgot password?*/}
                                {/*    </Link>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                    <Link href={`/register`} variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Copyright sx={{ mt: 5 }}  href="https://hivespark.io" name='hivespark' />
        </Container>
    );
}

export default SignIn;