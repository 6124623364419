import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';


function Copyright(props) {
    const { href, name, ...typographyProps } = props;

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...typographyProps}>
            {'Copyright © '}
            <Link color="inherit" href={href}>
                {name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const initialValues = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
};

const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
});




function Register() {
    let navigate = useNavigate();

    async function createPaymentIntent(name, email) {
        try {
            const response = await fetch(`https://ai.hivespark.io/api/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    email: email
                })
            });
            if (!response.ok) {
                toast.error('Error: Unable to pay');
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data.clientSecret);
            return data.clientSecret;
        } catch (err) {
            console.error(err);
            toast.error("Error: Unable to make a payment by Stripe");
            throw err;
        }
    }



    const handleSubmit = async (values, { setSubmitting }) => {

        // Send GTM data
        const dataLayer = {
            event: 'buttonClick',
            buttonName: 'Signup'
        };
        TagManager.dataLayer({ dataLayer });


        const submissionValues = {
            username: values.email,
            password: values.password,
            firstname: values.firstName,
            lastname: values.lastName,
        };

        try {
            const registerResponse = await fetch(`https://ai.hivespark.io/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submissionValues),
            });

            if (!registerResponse.ok) {
                toast.error('Error: Unable to register, if you have registered before, please login.');
                throw new Error('Network response was not ok');
            }

            // Attempt to create a payment intent after successful registration
            const clientSecret = await createPaymentIntent(values.lastName + " " + values.firstName, values.email);
            toast.success("You will use Stripe to make payment");
            console.log('clientSecret', clientSecret);
            navigate('/checkout', { state: { clientSecret: clientSecret, userEmail: values.email } });

        } catch (error) {
            console.error('There was an error!', error);
            toast.error("Error: Unable to proceed with registration or payment setup");
        } finally {
            setSubmitting(false); // Always turn off submitting
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{mb:'15px'}}>
                    Sign up
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                            </Grid>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Sign Up
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href={`/login`} variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Copyright sx={{ mt: 5 }}  href="https://hivespark.io" name="hivespark" />
        </Container>
    );
}

export default Register;