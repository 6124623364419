import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, List, ListItem, ListItemText, Divider, Box} from '@mui/material';

const products = [
    {
        name: 'Professional plan',
        desc: 'Monthly subscription',
        price: '$499.00',
    }
];



const features = [
    {
        title: 'Automation',
        description: '300+ tasks automated, surpassing Zapier.',
        example: 'Efficient B2B lead handling.'
    },
    {
        title: 'AI & Database Integration',
        description: 'AI-driven task management, broad database support.',
        example: 'Seamless CRM updates, accurate analytics.'
    },
    {
        title: 'Customization',
        description: 'Easy integration with major tools.',
        example: 'Agile market response with 1000+ integrations.'
    }
];

function FeatureList() {
    return (
        <Box>
            {features.map((feature, index) => (
                <React.Fragment key={index}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 1, px: 0 }}>
                        <Box flex="1">
                            <Typography variant="subtitle1">{feature.title}</Typography>
                            <Typography variant="body2" color="text.secondary">{feature.description}</Typography>
                        </Box>
                        <Typography variant="body2" flex="1">{feature.example}</Typography>
                    </Box>
                    {index < features.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </Box>
    );
}


function Info({ totalPrice }) {
    return (
        <React.Fragment>
            <Typography variant="subtitle2" color="text.secondary">
                Total
            </Typography>
            <Typography variant="h4" gutterBottom>
                {totalPrice}
            </Typography>
            <List disablePadding>
                {products.map((product) => (
                    <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
                        <ListItemText
                            sx={{ mr: 2 }}
                            primary={product.name}
                            secondary={product.desc}
                        />
                        <Typography variant="body1" fontWeight="medium">
                            {product.price}
                        </Typography>
                    </ListItem>
                ))}
            </List>
            <FeatureList />
        </React.Fragment>
    );
}

Info.propTypes = {
    totalPrice: PropTypes.string.isRequired,
};

export default Info;
