import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {Navigate, useParams} from "react-router-dom";

function Copyright(props) {
    const { href, name, ...typographyProps } = props;

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...typographyProps}>
            {'Copyright © '}
            <Link color="inherit" href={href}>
                {name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



export default function RefundNotification() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
                <Typography variant="h2" component="h1" gutterBottom>
                    Beta Program Full
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    {'Thank you for your interest in our beta program.'}
                    {'Unfortunately, we have reached our capacity for beta testers and are no longer accepting new participants.'}
                </Typography>
                <Typography variant="body1">
                    We have issued refunds to all participants who were not able to join due to the limit. We value your interest and support, and we apologize for any inconvenience this may have caused.
                </Typography>
            </Container>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="sm">
                    <Typography variant="body1">
                        For any inquiries, please contact our support team.
                    </Typography>
                    <Typography variant="body1">
                        Email: support@hivespark.io
                    </Typography>
                </Container>
                <Copyright sx={{ mt: 5 }}  href="https://hivespark.io" name="hivespark"/>
            </Box>
        </Box>

    );
}